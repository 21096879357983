import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SBPSPaymentForm from './components/SBPSPaymentForm/SBPSPaymentForm';
import Login from './components/Login';
import PaymentSuccess from './components/PaymentSuccess';
import PaymentError from './components/PaymentError';
import PaymentCancel from './components/PaymentCancel';
import ProductDetail from './components/ProductionDetail';

const App: React.FC = () => (
  <Router>
    <div className="App flex items-center justify-center min-h-screen bg-[rgba(211,211,211,0.1)]">
      <main className="w-[95%] max-w-md">
        <Routes>
          <Route path="/" element={<SBPSPaymentForm />} />
          <Route path="/login" element={<Login />} />
          <Route path="/product/:eventId" element={<ProductDetail />} />
          <Route path="/result/success" element={<PaymentSuccess />} />
          <Route path="/result/error" element={<PaymentError />} />
          <Route path="/result/cancel" element={<PaymentCancel />} />
        </Routes>
      </main>
    </div>
  </Router>
);

export default App;