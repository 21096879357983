import { ContributionEventList, ContributionEventListSchema } from "./types/contribution-event";

const NISHIAIZU_API_BASE_URL = process.env.REACT_APP_NISHIAIZU_API;

export const getContributionEventList = async (nishiaizuAccessToken: string): Promise<ContributionEventList> => {
  try {
    const response = await fetch(`${NISHIAIZU_API_BASE_URL}/contribution-event?contribution_category=MONEY&event_status=false`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${nishiaizuAccessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to get contribution event list');
    }

    const data = await response.json();
    const parsedData = ContributionEventListSchema.parse(data);

    return parsedData;
  } catch (error) {
    console.error('Error getting contribution event list:', error);
    throw error;
  }
};