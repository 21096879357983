import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginCustodial } from '../../api/login-custodial';

const Login: React.FC = () => {
  const [userId, setUserId] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    if (userId.trim().length === 0) {
      return;
    }

    const { nishiaizuAccessToken } = await loginCustodial(userId);
    sessionStorage.setItem('nishiaizuAccessToken', nishiaizuAccessToken);

    const eventIdForTest = "0a310d02-1b39-4771-a428-620284e06815";
    navigate(`/product/${eventIdForTest}`);
  };

  return (
    <div className="bg-white p-8 rounded-lg shadow-md">
      <form onSubmit={handleLogin}>
        <div className="mb-4">
          <label htmlFor="userId" className="block text-gray-700 text-sm font-bold mb-2">
            ユーザーID
          </label>
          <input
            type="text"
            id="userId"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          ログイン
        </button>
      </form>
    </div>
  );
};

export default Login;