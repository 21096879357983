import { z } from 'zod';
import { OrderDetail, OrderDetailSchema } from './order-details';

export type PurchaseItem = {
  event_id: string;
  count: number;
}

export type RegisterPayment = {
  purchases: PurchaseItem[];
}

export type RegisterPaymentResponse = {
  order_id: string;
  order_details: OrderDetail[];
  amount: number;
  otp: string;
}

export const PurchaseItemSchema: z.ZodType<PurchaseItem> = z.object({
  event_id: z.string().uuid().toLowerCase(),
  count: z.number().int().positive(),
});

export const RegisterPaymentSchema: z.ZodType<RegisterPayment> = z.object({
  purchases: z.array(PurchaseItemSchema).nonempty(),
});

export const RegisterPaymentResponseSchema: z.ZodType<RegisterPaymentResponse> = z.object({
  order_id: z.string().uuid(),
  order_details: z.array(z.lazy(() => OrderDetailSchema)),
  amount: z.number().int().positive(),
  otp: z.string().uuid(),
});
