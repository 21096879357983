import { LoginCustodialResponse, LoginCustodialResponseSchema } from "./types/login-custodial";

const NISHIAIZU_API_BASE_URL = process.env.REACT_APP_NISHIAIZU_API;

export const loginCustodial = async (userId: string): Promise<{
  nishiaizuAccessToken: string;
  response: LoginCustodialResponse,
}> => {
  const apiUrl = `${NISHIAIZU_API_BASE_URL}/login/custodial/shift/${userId}`;
  const response = await fetch(apiUrl, {
    method: 'POST',
  });

  if (!response.ok) {
    throw new Error('Failed to login custodial');
  }

  const data = await response.json();

  const nishiaizuAccessToken = response.headers.get('X-Questry-Nishi-Aizu');
  if (!nishiaizuAccessToken) {
    throw new Error('Failed to get NishiAizuAccessToken');
  }

  const parsedData = LoginCustodialResponseSchema.parse(data);
  return {
    nishiaizuAccessToken,
    response: parsedData,
  };
};
