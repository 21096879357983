import React, { useEffect, useState } from 'react';
import { Order } from './types';
import { generateBuyOrderArray, SBConvertToUTF8, SbHash } from '../../sbps';
import { createOrder } from '../../api/get-order-details';

const SBPSPaymentForm: React.FC = () => {
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchAndSubmitOrder = async () => {
      try {
        const orderData = await createOrder();
        submitForm(orderData);
      } catch (error) {
        console.error('Failed to fetch order:', error);
        setError('決済画面に遷移できません。お手数ですが、もう一度操作をやり直すか、お問い合わせにてご連絡ください。');
      }
    };

    fetchAndSubmitOrder();
  }, []);

  const submitForm = (orderData: Order) => {
    if (!process.env.REACT_APP_SBPS_PAYMENT_URL) {
      throw new Error('SBPS_PAYMENT_URL is not set');
    }
    const buyOrderArray = generateBuyOrderArray(orderData);
    const buyUtfData = SBConvertToUTF8(buyOrderArray);
    const buyHashData = SbHash(buyUtfData);

    const form = document.createElement('form');
    form.method = 'POST';
    form.action = process.env.REACT_APP_SBPS_PAYMENT_URL;
    form.acceptCharset = 'Shift_JIS';

    const addHiddenField = (name: string, value: string) => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = name;
      input.value = value;
      form.appendChild(input);
    };

    Object.entries(orderData).forEach(([key, value]) => {
      if ((value.toString().length > 0 || ["sps_cust_no", "sps_payment_no", "pay_item_id"].includes(key)) &&
          !["free_csv_input", "hashkey", "orderDetail"].includes(key)) {
        addHiddenField(key, value.toString());
      }
    });

    orderData.orderDetail.forEach((detail) => {
      Object.entries(detail).forEach(([key, value]) => {
        if (value.toString().length > 0) {
          addHiddenField(`${key}`, value.toString());
        }
      });
    });

    addHiddenField('sps_hashcode', buyHashData.toString());

    document.body.appendChild(form);
    form.submit();
  };

  if (error) {
    return (
      <div className="max-w-md mx-auto p-6 bg-white border border-gray-300 rounded-lg shadow-md">
        <div className="flex items-center justify-center mb-4">
          <svg className="w-8 h-8 text-red-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
          </svg>
          <h2 className="text-xl font-semibold text-gray-800">エラーが発生しました</h2>
        </div>
        <p className="text-center text-gray-600 mb-4">{error}</p>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="text-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900 mx-auto mb-4"></div>
        <p className="text-gray-600 font-semibold">決済画面に遷移中...</p>
      </div>
    </div>
  );
};

export default SBPSPaymentForm;