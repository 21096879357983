import { z } from 'zod';

export type GetOrderDetails = {}
export type GetOrderDetailsResponse = {
  order_id: string;
  cust_code: string;
  order_details: OrderDetail[];
  amount: number;
}

export type OrderDetail = {
  dtl_rowno: string;
  dtl_item_id: string;
  dtl_item_name: string;
  dtl_item_count: string;
  dtl_amount: string;
}

export const OrderDetailSchema: z.ZodType<OrderDetail> = z.object({
  dtl_rowno: z.string(),
  dtl_item_id: z.string(),
  dtl_item_name: z.string(),
  dtl_item_count: z.string(),
  dtl_amount: z.string(),
});
export const GetOrderDetailSchema: z.ZodType<GetOrderDetails> = z.object({});
export const GetOrderDetailsResponseSchema: z.ZodType<GetOrderDetailsResponse> = z.object({
  order_id: z.string(),
  cust_code: z.string(),
  order_details: z.array(OrderDetailSchema),
  amount: z.number(),
});
