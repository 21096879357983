import * as CryptoJS from 'crypto-js';
import { GetOrderDetailsResponse } from '../api/types/order-details';

interface OrderDetail {
  dtl_rowno: string;
  dtl_item_id: string;
  dtl_item_name: string;
  dtl_item_count: string;
  dtl_tax: string;
  dtl_amount: string;
  dtl_free1: string;
  dtl_free2: string;
  dtl_free3: string;
}

interface Order {
  orderDetail: OrderDetail[];
  pay_method: string;
  merchant_id: string;
  service_id: string;
  cust_code: string;
  sps_cust_no: string;
  sps_payment_no: string;
  order_id: string;
  item_id: string;
  pay_item_id: string;
  item_name: string;
  tax: string;
  amount: string;
  pay_type: string;
  service_type: string;
  terminal_type: string;
  success_url: string;
  cancel_url: string;
  error_url: string;
  pagecon_url: string;
  free1: string;
  free2: string;
  free3: string;
  free_csv_input: string;
  free_csv: string;
  request_date: string;
  limit_second: string;
  hashkey: string;
  sps_hashcode: string;
}

export const generateBuyOrder = (orderInfo: GetOrderDetailsResponse): Order => {
  const { REACT_APP_SBPS_MERCHANT_ID: merchant_id, 
          REACT_APP_SBPS_SERVICE_ID: service_id, 
          REACT_APP_SBPS_PAGECON_URL: pagecon_url, 
          REACT_APP_SBPS_SUCCESS_URL: success_url,
          REACT_APP_SBPS_CANCEL_URL: cancel_url,
          REACT_APP_SBPS_ERROR_URL: error_url,
          REACT_APP_SBPS_HASHKEY: hashkey } = process.env;

  if (!merchant_id || !service_id || !pagecon_url || !success_url || !cancel_url || !error_url || !hashkey) {
    console.error({ merchant_id, service_id, pagecon_url, success_url, cancel_url, error_url, hashkey });
    throw new Error('Required environment variables are not set');
  }

  const order_id = orderInfo.order_id;
  const free_csv_input = '';

  const orderDetail: OrderDetail[] = orderInfo.order_details.map((detail) => ({
    dtl_rowno: detail.dtl_rowno,
    dtl_item_id: detail.dtl_item_id,
    dtl_item_name: detail.dtl_item_name,
    dtl_item_count: detail.dtl_item_count,
    dtl_tax: "",
    dtl_amount: detail.dtl_amount,
    dtl_free1: "",
    dtl_free2: "",
    dtl_free3: "",
  }));

  return {
    orderDetail,
    pay_method: "credit3d2",
    merchant_id,
    service_id,
    cust_code: orderInfo.cust_code,
    sps_cust_no: "",
    sps_payment_no: "",
    order_id,
    item_id: orderDetail[0].dtl_item_id,
    pay_item_id: "",
    item_name: orderDetail[0].dtl_item_name,
    tax: "",
    amount: orderInfo.amount.toString(),
    pay_type: "0",
    service_type: "0",
    terminal_type: "0",
    success_url,
    cancel_url,
    error_url,
    pagecon_url,
    free1: "",
    free2: "",
    free3: "",
    free_csv: "",
    free_csv_input,
    request_date: getYYYYMMDDHHMMSS(),
    limit_second: "600",
    hashkey,
    sps_hashcode: "",
  };
};

export const generateBuyOrderArray = (order: Order): string[] => {
  order.free_csv = base64.encode(order.free_csv_input, 1);
  return [
    order.pay_method,
    order.merchant_id,
    order.service_id,
    order.cust_code,
    order.sps_cust_no,
    order.sps_payment_no,
    order.order_id,
    order.item_id,
    order.pay_item_id,
    order.item_name,
    order.tax,
    order.amount,
    order.pay_type,
    order.service_type,
    order.terminal_type,
    order.success_url,
    order.cancel_url,
    order.error_url,
    order.pagecon_url,
    order.free1,
    order.free2,
    order.free3,
    order.free_csv,
    ...order.orderDetail.flatMap(detail => [
      detail.dtl_rowno,
      detail.dtl_item_id,
      detail.dtl_item_name,
      detail.dtl_item_count,
      detail.dtl_tax,
      detail.dtl_amount,
      detail.dtl_free1,
      detail.dtl_free2,
      detail.dtl_free3,
    ]),
    order.request_date,
    order.limit_second,
    order.hashkey,
  ];
};

export const SBConvertToUTF8 = (arr: string[]): CryptoJS.lib.WordArray => {
  const str = arr.join('');
  return CryptoJS.enc.Utf8.parse(str);
};

export const SbHash = (utf8str: CryptoJS.lib.WordArray): CryptoJS.lib.WordArray => {
  return CryptoJS.SHA1(utf8str);
};

function getYYYYMMDDHHMMSS() {
  const now = new Date();
  return (
    now.getFullYear() +
    zeroPadding(now.getMonth() + 1) +
    zeroPadding(now.getDate()) +
    zeroPadding(now.getHours()) +
    zeroPadding(now.getMinutes()) +
    zeroPadding(now.getSeconds())
  );
}

function zeroPadding(num: number) {
  return num < 10 ? '0' + num : num + '';
}

const base64 = {
  encode: (input: string, uriSafe: number): string => {
    const wordArray = CryptoJS.enc.Utf8.parse(input);
    const base64String = CryptoJS.enc.Base64.stringify(wordArray);
    return uriSafe ? base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '') : base64String;
  },
  decode: (base64String: string): string => {
    const uriSafe = base64String.replace(/-/g, '+').replace(/_/g, '/');
    return CryptoJS.enc.Base64.parse(uriSafe).toString(CryptoJS.enc.Utf8);
  },
};
