import { z } from 'zod';

export type ContributionEvent = {
  event_id: string;
  name: string;
  description: string;
  price: number;
  purchase_limit: number;
  event_image_url: string;
  tokenAppCount: number;
}

export type ContributionEventList = ContributionEvent[];

const ContributionEventSchema = z.object({
  event_id: z.string(),
  name: z.string(),
  description: z.string(),
  price: z.number(),
  purchase_limit: z.number(),
  event_image_url: z.string(),
  tokenAppCount: z.number(),
});

export const ContributionEventListSchema = z.array(ContributionEventSchema);
