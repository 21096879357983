import { RegisterPayment, RegisterPaymentResponse, RegisterPaymentResponseSchema } from './types/register-payment';

const NISHIAIZU_API_BASE_URL = process.env.REACT_APP_NISHIAIZU_API;

export const registerPayment = async (
  paymentData: RegisterPayment,
  nishiaizuAccessToken: string
): Promise<RegisterPaymentResponse> => {
  try {
    const response = await fetch(`${NISHIAIZU_API_BASE_URL}/payment/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${nishiaizuAccessToken}`,
      },
      body: JSON.stringify(paymentData),
    });

    if (!response.ok) {
      throw new Error('Failed to register payment');
    }

    const data = await response.json();
    const parsedData = RegisterPaymentResponseSchema.parse(data);

    return parsedData;
  } catch (error) {
    console.error('Error registering payment:', error);
    throw error;
  }
}