const NISHIAIZU_API_BASE_URL = process.env.REACT_APP_NISHIAIZU_API;

export const cancelPayment = async (nishiaizuAccessToken: string) => {
  try {
    const response = await fetch(`${NISHIAIZU_API_BASE_URL}/payment/cancel`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${nishiaizuAccessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to cancel payment');
    }
  } catch (error) {
    console.error('Error canceling payment:', error);
    throw error;
  }
}