import { z } from 'zod';

export type LoginCustodialResponse = {
  member_id: string;
  wallet_address: string;
  screen_name: string;
}
export const LoginCustodialResponseSchema = z.object({
  member_id: z.string(),
  wallet_address: z.string(),
  screen_name: z.string(),
});
