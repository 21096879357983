import React from 'react';

const PaymentCancel: React.FC = () => {
  return (
    <div className="bg-gray-50 p-8 rounded-lg shadow-sm text-center">
      <h2 className="text-xl font-semibold mb-4 text-gray-700">支払いキャンセル</h2>
      <p className="mb-4 text-gray-600">支払いがキャンセルされました。</p>
      <div className="mt-6">
        <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>
    </div>
  );
};

export default PaymentCancel;