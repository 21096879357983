import React from 'react';

const PaymentError: React.FC = () => {
  return (
    <div className="bg-gray-50 p-8 rounded-lg shadow-sm text-center">
      <h2 className="text-xl font-semibold mb-4 text-gray-700">支払いエラー</h2>
      <p className="mb-4 text-gray-600">申し訳ありませんが、支払い処理中にエラーが発生しました。</p>
      <div className="mt-6">
        <svg className="mx-auto h-12 w-12 text-red-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </div>
    </div>
  );
};

export default PaymentError;