import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ContributionEvent } from '../../api/types/contribution-event';
import { getContributionEventList } from '../../api/get-contribution-event-list';
import { registerPayment } from '../../api/register-payment';
import Cookies from 'js-cookie';
import { cancelPayment } from '../../api/cancel-payment';
import { RegisterPaymentResponse } from '../../api/types/register-payment';

const ProductDetail: React.FC = () => {
  const { eventId } = useParams();

  const [product, setProduct] = useState<ContributionEvent | null>(null);
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProduct = async () => {
      const nishiaizuAccessToken = sessionStorage.getItem('nishiaizuAccessToken') || '';
      if (!nishiaizuAccessToken) {
        setError('ログインしていません。');
        setLoading(false);
        return;
      }

      try {
        const fetchedProducts = await getContributionEventList(nishiaizuAccessToken);
        const product = fetchedProducts.find((p) => p.event_id.toLowerCase() === eventId?.toLowerCase());
        if (product) {
          setProduct(product);
        } else {
          setError('商品が見つかりません。');
        }
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch product:', error);
        setError('商品情報の取得に失敗しました。');
        setLoading(false);
      }
    };

    fetchProduct();
  }, [eventId]);

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuantity = parseInt(e.target.value);
    if (product && newQuantity >= 1 && newQuantity <= (product.purchase_limit - product.tokenAppCount)) {
      setQuantity(newQuantity);
    }
  };

  const handleProceedToPayment = async () => {
    const nishiaizuAccessToken = sessionStorage.getItem('nishiaizuAccessToken') || '';
    if (!nishiaizuAccessToken) {
      setError('ログインしていません。');
      setLoading(false);
      return;
    }
    if (product) {
      let response: RegisterPaymentResponse;
      try {
        response = await registerPayment({
          purchases: [{
            event_id: product.event_id,
            count: quantity,
          }],
        }, nishiaizuAccessToken);
        Cookies.set('OTP', response.otp);
        navigate('/');
      } catch (error) {
        console.error('Failed to register payment:', error);
        setError('進行中の決済があるので決済を登録できませんでした。数秒後にキャンセルされますので、もう一度決済に進んで下さい。');
        await new Promise(resolve => setTimeout(resolve, 3000));
        await cancelPayment(nishiaizuAccessToken);
        window.location.reload();
      }
    }
  };

  if (loading) {
    return <div className="text-center">読み込み中...</div>;
  }

  if (error || !product) {
    return <div className="text-center text-red-500">{error || '商品が見つかりません。'}</div>;
  }

  return (
    <div className="bg-white p-8 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">{product.name}</h2>
      <img src={product.event_image_url} alt={product.name} className="w-full h-64 object-contain mb-4 rounded" />
      <p className="mb-4">{product.description}</p>
      <p className="font-bold mb-2">価格: ¥{product.price.toLocaleString()}</p>
      
      <div className="mb-4">
        <label htmlFor="quantity" className="block mb-2">数量:</label>
        <input
          id="quantity"
          type="number"
          min="1"
          max={product.purchase_limit - product.tokenAppCount}
          value={quantity}
          onChange={handleQuantityChange}
          className="border rounded p-2 w-20 mr-2"
        />
      </div>

      <p className="font-bold mb-4">
        合計: ¥{(product.price * quantity).toLocaleString()}
      </p>

      <button
        onClick={handleProceedToPayment}
        className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors"
      >
        決済に進む
      </button>
    </div>
  );
};

export default ProductDetail;