import Cookies from 'js-cookie';
import { generateBuyOrder, generateBuyOrderArray, SBConvertToUTF8, SbHash } from '../sbps';
import { GetOrderDetailsResponseSchema } from './types/order-details';

const NISHIAIZU_API_BASE_URL = process.env.REACT_APP_NISHIAIZU_API;

const getOrderDetails = async () => {
  const otp = Cookies.get('OTP');
  
  if (!otp) {
    throw new Error('Failed to get OTP');
  }

  try {
    const response = await fetch(
      `${NISHIAIZU_API_BASE_URL}/payment/order-details`,
      {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'X-OTP': otp
        }
      }
    );

    if (!response.ok) {
      throw new Error('Failed to call getOrderDetails API');
    }

    const data = await response.json();
    const parsedData = GetOrderDetailsResponseSchema.parse(data);

    return parsedData;
  } catch (error) {
    console.error('Failed to getOrderDetails:', error);
    throw error;
  }
};

export const createOrder = async () => {
  try {
    const orderDetails = await getOrderDetails();
    const order = generateBuyOrder(orderDetails);

    const buyOrderArray = generateBuyOrderArray(order);
    const buyUtfData = SBConvertToUTF8(buyOrderArray);
    const buyHashData = SbHash(buyUtfData);
    
    order.sps_hashcode = buyHashData.toString();

    return order;
  } catch (error) {
    console.error('Failed to createOrder:', error);
    throw error;
  }
};
